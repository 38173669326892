import React from 'react';
import PropTypes from 'prop-types';
import HeroComponent from '@fuww/library/src/Hero';
import {
  DesktopOnlyContainer as DefaultDesktopOnlyContainer,
  MobileOnlyContainer as DefaultMobileOnlyContainer,
}
from '@fuww/library/src/ImageContainer';
import {
  defaultHeader, DesktopOnlyImage, MobileOnlyImage,
} from './Company/HeaderImage';

const Hero = ({
  imageUrls,
  alt,
  MobileOnlyContainer,
  DesktopOnlyContainer,
}) => {
  const mobileHeaderUrls = imageUrls
    ? imageUrls.mobile
    : defaultHeader.default.mobile;

  const desktopHeaderUrls = imageUrls
    ? imageUrls.desktop
    : defaultHeader.default.desktop;

  return (
    <HeroComponent
      image={(
        <>
          <MobileOnlyImage
            title={alt}
            imageUrls={mobileHeaderUrls}
            MobileOnlyContainer={MobileOnlyContainer}
          />
          <DesktopOnlyImage
            title={alt}
            imageUrls={desktopHeaderUrls}
            DesktopOnlyContainer={DesktopOnlyContainer}
            disableRoundedCorners
          />
        </>
    )}
    />
  );
};

Hero.propTypes = {
  MobileOnlyContainer: PropTypes.elementType,
  DesktopOnlyContainer: PropTypes.elementType,
  imageUrls: PropTypes.shape({
    mobile: PropTypes.arrayOf(PropTypes.string),
    desktop: PropTypes.arrayOf(PropTypes.string),
  }),
  alt: PropTypes.string,
};

Hero.defaultProps = {
  MobileOnlyContainer: DefaultMobileOnlyContainer,
  DesktopOnlyContainer: DefaultDesktopOnlyContainer,
  imageUrls: undefined,
  alt: 'Hero image',
};

export default Hero;
