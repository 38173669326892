import React from 'react';
import styled from '@emotion/styled';
import PropTypes, { InferProps } from 'prop-types';
import { Grid, GridCell } from './Grid';
import Headline3 from './Typography/Heading/Headline3';
import InnerHtmlStyling from './InnerHtmlStyling';
import Headline4 from './Typography/Heading/Headline4';
import Button from './Button';
import Anchor from './Anchor';

const LiteHomePageButtonButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  margin: 2rem 0;
  width: 100%;

  @media (min-width: 600px) {
    flex-direction: row;
    margin: 4rem 0;
  }
`;

const Content = styled(InnerHtmlStyling)`
  font-family: var(--font-primary)
`;

const Header = styled(Headline3)`
  margin-bottom: 1.5rem;
`;

const Payoff = styled(Headline4)`
  margin-top: 2rem;
  margin-bottom: 1.5rem;
`;

const PageWrapper = styled.div`
  max-width: 735px;
  box-sizing: border-box;
  width: 100%;
  padding: 0 16px;
  margin: auto;
  text-align: center;
`;

const liteHomePagePropertyTypes = {
  businessIntelligenceButtonLabel: PropTypes.string.isRequired,
  jobsButtonLabel: PropTypes.string.isRequired,
  newsButtonLabel: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  payoff: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

type LiteHomePageProperties = InferProps<
  typeof liteHomePagePropertyTypes
>;

const LiteHomePage = ({
  businessIntelligenceButtonLabel,
  jobsButtonLabel,
  newsButtonLabel,
  content,
  payoff,
  title,
}: LiteHomePageProperties) => (
  <Grid>
    <GridCell padding="0">
      <PageWrapper>
        <LiteHomePageButtonButtonGroup>
          <Anchor styled href="https://fashionunited.com/">
            <Button variant="contained">
              {newsButtonLabel}
            </Button>
          </Anchor>
          <Anchor styled href="/fashionjobs">
            <Button variant="contained">
              {jobsButtonLabel}
            </Button>
          </Anchor>
          <Anchor styled href="https://fashionunited.com/i">
            <Button variant="contained">
              {businessIntelligenceButtonLabel}
            </Button>
          </Anchor>
        </LiteHomePageButtonButtonGroup>
        <Header tag="h3">{title}</Header>
        <Content>{content}</Content>
        <Payoff tag="h4">{payoff}</Payoff>
      </PageWrapper>
    </GridCell>
  </Grid>
);

LiteHomePage.propTypes = liteHomePagePropertyTypes;

export default LiteHomePage;
