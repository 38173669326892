import { NextPageWithLayout } from 'next';
import React, { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import LiteHomePageComponent from '@fuww/library/src/LiteHomePage';
import Layout from '../components/Layout';
import messages from '../lib/messages.mjs';
import Head from '../components/Head';
import Hero from '../components/Hero';

const headerImageUrls = {
  mobile: [
    'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=34,quality=10,width=42/https://media.fashionunited.com/media/images/lite-page-mobile.png',
    'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=288,quality=70,width=360/https://media.fashionunited.com/media/images/lite-page-mobile.png',
    'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=576,quality=70,width=720/https://media.fashionunited.com/media/images/lite-page-mobile.png',
  ],
  desktop: [
    'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=8,quality=10,width=42/https://media.fashionunited.com/media/images/lite-page-desktop.png',
    'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=277,quality=70,width=1350/https://media.fashionunited.com/media/images/lite-page-desktop.png',
    'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=526,quality=70,width=2560/https://media.fashionunited.com/media/images/lite-page-desktop.png',
    'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=1052,quality=70,width=5120/https://media.fashionunited.com/media/images/lite-page-desktop.png',
  ],
};

const LiteHomePage: NextPageWithLayout = () => {
  const intl = useIntl();

  return (
    <>
      <Head
        title={intl.formatMessage(messages.indexTitle)}
        description={intl.formatMessage(messages.indexDescription)}
      />
      <LiteHomePageComponent
        businessIntelligenceButtonLabel={(
          intl.formatMessage(messages['info.features.businessIntelligence'])
        )}
        jobsButtonLabel={(intl.formatMessage(messages.jobs))}
        newsButtonLabel={(intl.formatMessage(messages.news))}
        content={(
          <p className="text-center my-4">
            FashionUnited is the online platform
            for fashion professionals, which combines the latest fashion news,
            career opportunities, business intelligence and crucial industry
            insights on just one platform in 20+ countries and 8 languages.
            Supporting fashion professionals and -enterprises is at our heart.
            For that reason we provide our visitors with high-quality, to the
            point insights and information in their local language, next to
            offering customized HR &amp; Marketing solutions to our
            international customers. Over 1 million industry experts visit
            FashionUnited every month to be in the know, to start a new
            professional challenge or to drive their fashion business even
            further.
          </p>
        )}
        payoff="The Industry Network"
        title={intl.formatMessage(messages.indexTitle)}
      />
    </>
  );
};

LiteHomePage.getLayout = (page: ReactElement) => (
  <Layout
    header={(
      <Hero
        alt="Banner"
        imageUrls={headerImageUrls}
      />
  )}
  >
    {page}
  </Layout>
);

export default LiteHomePage;
